import React, { useState } from "react";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  CssBaseline,
  Toolbar,
  Typography,
} from "@material-ui/core";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import BusinessIcon from "@material-ui/icons/Business";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((_) => ({
  main: {
    "padding-top": 56,
  },
}));

function Navigation() {
  const [value, setValue] = useState<any>(0);
  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event: any, newValue: any) => {
        setValue(newValue);
      }}
    >
      <BottomNavigationAction
        label="Activity"
        icon={<RssFeedIcon />}
        component={Link}
        to={"/"}
      />
      <BottomNavigationAction label="Roasters" icon={<BusinessIcon />} />
      <BottomNavigationAction
        label="Coffees"
        icon={<LocalCafeIcon />}
        component={Link}
        to={"/coffees"}
      />
    </BottomNavigation>
  );
}

function AppShell({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <AppBar>
        <Toolbar>
          <Typography variant="h6">Caffeinators Club</Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.main}>{children}</main>
      <AppBar
        position="fixed"
        style={{ top: "auto", bottom: 0 }}
        component="footer"
      >
        <Navigation />
      </AppBar>
    </>
  );
}

export default AppShell;
