import React, { useContext } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { AuthContext } from "./AuthContext";
import { publicFetch } from "./utils/fetch";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {},
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

function Login() {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const onLogin = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("username", formData.get("username") as string);
    urlSearchParams.append("password", formData.get("password") as string);
    const response = await publicFetch.post("/login", {
      username: formData.get("username"),
      password: formData.get("password"),
    });
    const token = response.data.token;
    const decodedToken: any = jwtDecode(token);
    authContext.setAuthState({
      user: decodedToken.user,
      token,
      expiresAt: new Date(decodedToken.exp * 1000),
      roles: decodedToken.roles,
    });
    console.log(decodedToken);
    history.push("/");
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={onLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
export default Login;
