import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  List,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Formik } from "formik";

const COFFEES_QUERY = gql`
  query AllCoffees {
    allCoffees {
      id
      name
      coffeeRoasters
      imageUrl
    }
  }
`;

const ADD_COFFE_MUTATION = gql`
  mutation AddCoffee(
    $name: String!
    $coffeeRoasters: String!
    $imageUrl: String
  ) {
    addCoffee(
      data: {
        coffeeRoasters: $coffeeRoasters
        name: $name
        imageUrl: $imageUrl
      }
    ) {
      id
      name
      coffeeRoasters
      imageUrl
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(9),
    right: theme.spacing(2),
  },
}));

export function Coffees() {
  const { loading, error, data } = useQuery(COFFEES_QUERY, {});
  const [addCoffee] = useMutation(ADD_COFFE_MUTATION, {});
  const classes = useStyles();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClickOpen = () => {
    setAddDialogOpen(true);
  };
  const handleClose = () => {
    setAddDialogOpen(false);
  };
  if (loading) return <p>loading...</p>;
  if (error) return <p>{error}</p>;
  console.log(data);
  return (
    <>
      <List className={classes.root}>
        {data.allCoffees.map((coffee: any) => {
          return (
            <ListItem key={coffee.id}>
              <ListItemText>{coffee.name}</ListItemText>
            </ListItem>
          );
        })}
      </List>
      <Dialog
        open={addDialogOpen}
        onClose={handleClose}
        fullScreen={fullScreen}
      >
        <DialogTitle>Add Coffee</DialogTitle>
        <DialogContent>
          <DialogContentText>Hello World</DialogContentText>
          <Formik
            initialValues={{ name: "" }}
            onSubmit={async (values, { setSubmitting }) => {
              await addCoffee({
                variables: { name: values.name, coffeeRoasters: "Dalmeyer" },
                update: (cache, { data: { addCoffee } }) => {
                  const data = cache.readQuery<any>({ query: COFFEES_QUERY });
                  cache.writeQuery({
                    query: COFFEES_QUERY,
                    data: { allCoffees: [...data.allCoffees, addCoffee] },
                  });
                },
              });
              setSubmitting(false);
              setAddDialogOpen(false);
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  autoFocus
                  id="name"
                  label="Name of the Coffee"
                  fullWidth
                  {...formik.getFieldProps("name")}
                />
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={formik.isSubmitting}
                  >
                    Save new Coffee
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <Fab
        className={classes.fab}
        color="secondary"
        aria-label="add"
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>
    </>
  );
}
