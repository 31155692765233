import React from "react";
import { useHistory } from "react-router-dom";

const AuthContext = React.createContext<{
  authState: AuthState;
  setAuthState: (authState: AuthState) => void;
  isAuthenticated: () => boolean;
  logout: () => void;
}>({
  authState: {
    user: null,
    token: null,
    expiresAt: null,
    roles: [],
  },
  setAuthState: () => {},
  isAuthenticated: () => false,
  logout: () => {},
});
const { Provider } = AuthContext;
const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const user = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const expiresAt =
    localStorage.getItem("expiresAt") !== null
      ? new Date(parseInt(localStorage.getItem("expiresAt")!, 10))
      : null;
  const roles = localStorage.getItem("roles")
    ? JSON.parse(localStorage.getItem("expiresAt")!)
    : [];
  const [authState, setAuthState] = React.useState<AuthState>({
    user,
    token,
    expiresAt,
    roles,
  });
  const history = useHistory();

  const setAuthInfo = ({ token, user, expiresAt, roles }: AuthState) => {
    setAuthState({ token, user, expiresAt, roles });
    localStorage.setItem("token", token!);
    localStorage.setItem("user", user!);
    localStorage.setItem("expiresAt", `${expiresAt!.getTime()}`);
    localStorage.setItem("roles", JSON.stringify(roles));
  };
  const isAuthenticated = () => {
    if (!authState.token || !authState.expiresAt) {
      return false;
    }
    return new Date().getTime() < authState.expiresAt.getTime();
  };

  const logout = () => {
    setAuthState({
      user: null,
      token: null,
      expiresAt: null,
      roles: [],
    });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("expiresAt");
    localStorage.removeItem("roles");

    history.push("/login");
  };

  return (
    <Provider
      value={{ authState, setAuthState: setAuthInfo, isAuthenticated, logout }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };

export interface AuthState {
  user: string | null;
  token: string | null;
  expiresAt: Date | null;
  roles: string[];
}
