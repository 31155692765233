import React, { useContext } from "react";
import { AuthContext, AuthProvider } from "./AuthContext";
import AppShell from "./AppShell";
import {
  BrowserRouter,
  BrowserRouterProps,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { RouteProps } from "react-router";

import Login from "./Login";
import { Coffees } from "./Coffees";
import { ApolloClientProvider } from "./ApolloProvider";

function AuthenticatedRoute({
  children,
  ...rest
}: BrowserRouterProps &
  RouteProps & {
    children: React.ReactNode;
  }) {
  const authContext = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={() =>
        authContext.isAuthenticated() ? (
          <>{children}</>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function AppRoutes() {
  return (
    <Switch>
      <AuthenticatedRoute exact path="/">
        <AppShell>
          <p>Home</p>
        </AppShell>
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/coffees">
        <AppShell>
          <Coffees />
        </AppShell>
      </AuthenticatedRoute>
      <Route path="/login">
        <Login />
      </Route>
    </Switch>
  );
}

export function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ApolloClientProvider>
          <AppRoutes />
        </ApolloClientProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
